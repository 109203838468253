<template>
  <div class="login-container">
    <div class="login-content">
      <img src="../../assets/login_logo.png" style="width: 240px;height: auto">
      <div class="login-item" style="margin-top: 50px">
        <p class="title">Email</p>
        <input class="login-input" v-model="email" placeholder="Please enter your email">
      </div>
      <div class="login-item" style="margin-top: 20px">
        <p class="title">Password</p>
        <input class="login-input" v-model="pwd" type="password" placeholder="Please enter the password">
      </div>
      <div class="btn-login" @click="submit">Login</div>
    </div>
  </div>
</template>
<script>
import { setAccountName, setLastName, setUserId, setUserName } from '../../utils/store'
import { loginByStudent } from '../../api/ipsye'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      pwd: ''
    }
  },
  methods: {
    submit() {
      if (this.email == '') {
        this.$message.error('Please enter your email')
        return
      }
      if (this.pwd == '') {
        this.$message.error('Please enter the password')
        return
      }
      loginByStudent(this.email, this.pwd).then((res) => {

        if (res.data.code != 0) {

          this.$message.error(res.data.msg)
          return
        }

        let result = res.data.data
        setUserId(result._id.$id)
        let user_name = result.student_givenName_pinyin + ' ' + result.student_lastName_pinyin
        let show_name = ''
        if (result.student_lastName_pinyin) {
          show_name = result.student_lastName_pinyin.slice(0, 1)
        }
        if (result.student_givenName_pinyin) {
          show_name = show_name + result.student_givenName_pinyin.slice(0, 1)
        }
        setUserName(user_name)
        if (show_name != '') {
          setLastName(show_name.toUpperCase())
        } else {
          setLastName(result.student_lastName)
        }

        this.$UserData.user_name = user_name
        if (result.account_nameEN) {
          let account_name = result.account_nameEN
          if (!account_name || account_name == '') {
            account_name = result.account_name
          }
          setAccountName(account_name)
          this.$UserData.account_name = account_name
          this.$UserData.user_id = result._id.$id
        }
        this.$router.push('/')
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.login-container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/login_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-content {
  padding: 75px 50px;
  box-sizing: border-box;
  background: #FFFFFF;
  text-align: center;
  box-shadow: 0px 0px 64px 1px #A80109;
  border-radius: 24px 24px 24px 24px;
}


.login-item {
  width: 400px;
  height: 60px;
  background: rgba(204, 53, 60, 0.08);
  border-radius: 29px 29px 29px 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 20px;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
    font-weight: normal;
    color: #CC353C;
  }
}

.login-input {
  box-sizing: border-box;
  padding-left: 15px;
  background-color: transparent;
  border: none;
  flex: 1;
  height: 100%;
  outline: none;
  font-size: 18px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #6E6E6E;
}

.btn-login {
  margin-top: 50px;
  cursor: pointer;
  text-align: center;
  line-height: 60px;
  width: 400px;
  height: 60px;
  background: #CC353C;
  border-radius: 32px 32px 32px 32px;
  border: 2px solid #CC353C;
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #FFFFFF;
}
</style>
